import { Button, TextField } from "@mui/material"
import { css } from "glamor"
import React, { ReactNode, useState } from "react"
import { FACEBOOK_BG, FACEBOOK_BLUE, FACEBOOK_BLACK } from "../../assets/colors/Colors"
import { GlobalConfig, validateConfig } from "../composables/Layout"
import X from "../composables/X"
import SpacerV from "../sections/SpacerV"
import CircularProgress from '@mui/material/CircularProgress';
import { AuthLogo, AuthLogoLgCentered, FineprintRsp, SubtitleIIRsp, SubtitleRsp, TitleRspAlt } from "./Auth"
import { navigate } from "gatsby"

enum Constants {
    GUTTER = 40,
    FIELD_GUTTER = 16,
    FINEPRINT_GUTTER = 8
    
}

const Content = {
    signup : {
        title : `It's Who You Know`,
        subtitle : 'Seriously. Welcome.',
        fineprint  : 'By signing up, you agree to the Terms of Service and Privacy Policy, including Cookie Use.',
        hasAccount : `Already have an Account?`,
        signUpBtn : 'Sign Up'
    }
}



export const Signup = ( props: { 
    children ?: any,
    username ?: string,
    setUsername ?: ( value : string ) => void 
    password ?: string,
    setPassword ?: ( value : string ) => void 
    passwordVerify ?: string,
    setPasswordVerify ?: ( value : string ) => void 
    awaitingSignin ?: boolean,
    onClick ?: () => void 

}) => {
   const config : GlobalConfig = validateConfig()
   //const signupBtnValue = useState<string|ReactNode>(Content.signup.signUpBtn)
   //const [signupBtnToggle, setSignupBtnToggle ] = useState(true)
   const [fineprintValue, setFineprintValue ] = useState(Content.signup.fineprint)
   const [fineprintColor, setFineprintColor ] = useState('silver')


   const signupBtnValue = props.awaitingSignin 
        ? <CircularProgress style={{ height : 20, width : 20, color : 'white'}}/>
        : Content.signup.signUpBtn
   


   return <div style={{ width : '100%', height : '100%', overflowX : 'scroll' }}>
     <SpacerV h={Constants.GUTTER}/>
     <AuthLogoLgCentered/>
     <SpacerV h={Constants.GUTTER}/>
     
     <TitleRspAlt c={FACEBOOK_BLACK}>{Content.signup.title}</TitleRspAlt>
     <SubtitleRsp c={FACEBOOK_BLACK}>{Content.signup.subtitle}</SubtitleRsp>
        
        <SpacerV h={Constants.GUTTER}/>
        <X style={{ height : 55 }}>
            <TextField value={ props.username } 
                id="outlined-basic" 
                label="Email" 
                variant="outlined" 
                style={{ width : 350, marginLeft : 10, marginRight : 10 }} 
                onChange={( event )=>{
                    if( props.setUsername )
                        props.setUsername(event.target.value)
                }}
            />
        </X>

        <SpacerV h={Constants.FIELD_GUTTER}/>
        <X style={{ height : 55 }}>
            <TextField value={ props.password } 
                id="outlined-basic" 
                label="Password" 
                type="password"
                variant="outlined" 
                style={{ width : 350, marginLeft : 10, marginRight : 10 }} 
                onChange={( event )=>{
                    if( props.setPassword )
                        props.setPassword(event.target.value)
                    
                }}
            />
        </X>


        <SpacerV h={Constants.FIELD_GUTTER}/>
        <X style={{ height : 55 }}>
            <TextField value={ props.passwordVerify } 
                id="outlined-basic" 
                label="Password" 
                type="password"
                variant="outlined" 
                style={{ width : 350, marginLeft : 10, marginRight : 10 }} 
                onChange={( event )=>{
                    if( props.setPasswordVerify )
                        props.setPasswordVerify(event.target.value)
                    
                }}
            />
        </X>
        
        <SpacerV h={Constants.FIELD_GUTTER}/>
        <X style={{ height : 55 }}>
            <Button
                disabled={ props.awaitingSignin }
                style={{ 
                    width : 350, 
                    marginLeft : 10, 
                    marginRight : 10,
                    height : 55, 
                    backgroundColor: FACEBOOK_BLACK, 
                    color: 'white',
                }}
                type="submit"
                
                variant="contained"
                onClick={()=>{
            
                    if( props.onClick )
                        props.onClick()
                }}
            >{signupBtnValue}</Button>
        </X>

        <SpacerV h={Constants.FINEPRINT_GUTTER}/>
        <X style={{ height : 55}}>
            <FineprintRsp w={360} c={fineprintColor}>{fineprintValue}</FineprintRsp>    
        </X>
        
        <SpacerV h={Constants.FIELD_GUTTER}/>
        <SubtitleIIRsp c={FACEBOOK_BLACK} >{Content.signup.hasAccount}</SubtitleIIRsp>

        <SpacerV h={Constants.FIELD_GUTTER}/>
        <X style={{ height : 55 }}>
            <Button
                style={{ 
                    width : 350, 
                    height : 55, 
                    marginLeft : 10,
                    marginRight : 10,
                    color: FACEBOOK_BLACK, 
                    backgroundColor: 'white',
                    border : `2px solid ${FACEBOOK_BLACK}`

                }}
                type="submit"
                
                variant="contained"
                onClick={()=>{
                    navigate("/auth/sign-in")
                }}
            >Sign In Here</Button>
        </X>
        <SpacerV h={Constants.FIELD_GUTTER*2}/>
        
                    
   </div>
}





