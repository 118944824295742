import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth';
import { navigate } from 'gatsby';
import React from 'react'
import { auth, isLoggedIn } from '../../../firebase-config';
import CobraHeader from '../../client-ui/shared/CobraHeader';
import { Signin } from '../../locust/components/Signin';
import { LayoutFS } from '../../locust/composables/Layout';
import { getShoppingCart } from '../../service/shopping-cart';
import { Signup } from '../../locust/components/Signup';
import { ToastContainer, toast } from 'react-toastify';
//import Register, { DontSeeYourRegistrationEmail } from '../../locust/components/Register';
//import { setTempSavePW } from '../../service/auth-service';
import { getUIDMain } from '../../service/user-service';
import { saveTempDataFBPortal_TEMP_00 } from '../../../firebase/firebase-portal-temp-00'; 
import { ENABLE_ERROR_LOGGING } from '../../settings/project-settings';
import { isLoggedInPortal_STORAGE_00, portalAuthStorage00 } from '../../../firebase/firebase-portal-storage-00';


enum PageState {
    SIGN_UP,
    CHECK_YOUR_EMAIL,
}

export default () => {

    const [pageState, setPageState] = React.useState(PageState.SIGN_UP)
    const [awaitingSignin, setAwaitingSignin] = React.useState(false)
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [passwordVerify, setPasswordVerify] = React.useState("")
    
    
    return (
        <>

            <LayoutFS min={350}>
                <CobraHeader type={'store'} shoppingCart={Object.keys(getShoppingCart()).length}/>
                
                { PageState.SIGN_UP === pageState && 
                    <Signup
                        awaitingSignin={awaitingSignin}
                        username={username}
                        password={password}
                        passwordVerify={passwordVerify}
                        setPassword={setPassword}
                        setUsername={setUsername}
                        setPasswordVerify={setPasswordVerify}
                        onClick={ async ()=>{

                            // const passwordsDifferent : boolean = password !== passwordVerify
                            // const passwordEmpty : boolean = password.length < 6 || passwordVerify.length < 6 
                            // const emailBlank : boolean = username.length < 6
                            // if( passwordsDifferent ) toast("Passwords Must Match");
                            // if( passwordEmpty ) toast("Passwords Must be at Least 6 Characters");
                            // if( emailBlank ) toast("Email Must Contain at Least 6 Characters");
                            // if( passwordsDifferent || passwordEmpty || emailBlank ) return 
                                
                            setAwaitingSignin(true)
                            
                            if( isLoggedIn() ) await auth.signOut()
                            if( isLoggedInPortal_STORAGE_00()) await portalAuthStorage00.signOut()

                            createUserWithEmailAndPassword(auth,username,password)
                                .then( async ( userCredential )=>{
                                    
                                    await saveTempDataFBPortal_TEMP_00( username.toLowerCase(), {
                                        uid : userCredential.user.uid, 
                                        password,
                                    })



                                    sendEmailVerification(userCredential.user).then(()=>{
                                        setTimeout(()=>{
                                            navigate("/auth/resend-registration")
                                            setAwaitingSignin(false)
                                        },1000)
                                    })  
                            }).catch(( error )=>{
                                if(ENABLE_ERROR_LOGGING) console.log( error.code )
                                if(error.code === "auth/email-already-in-use")
                                    toast("Woops! This email is already registered to an existing Slap Kard account.")
                                    setAwaitingSignin(false)
                            })

                            //navigate("/dashboard")
                        
                        }}
                    />
                }

                {/* { PageState.CHECK_YOUR_EMAIL === pageState && <DontSeeYourRegistrationEmail/> } */}

           
            </LayoutFS>
            <ToastContainer toastStyle={{ backgroundColor: "crimson", color : 'white', top : -20 }} hideProgressBar autoClose={2000} position="bottom-right"/>
        
        </>
    )
}
